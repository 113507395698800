import { ActionType, createAction, props } from '@ngrx/store';
import { AgencyConfigInfo } from '../../models/AgencyConfig';

export const GET_AGENCY_CONFIG = '[Agency config] Get';
export const GET_AGENCY_CONFIG_SUCCESS = '[Agency config] Get Success';
export const GET_AGENCY_CONFIG_SUCCESS2 = '[Agency config] Get Success2';

export const GET_AGENCY_CONFIG_FAILED = '[Agency config] Get Failed';

export const getAgencyConfig = createAction(GET_AGENCY_CONFIG);
export const getAgencyConfigSuccess = createAction(
  GET_AGENCY_CONFIG_SUCCESS,
  props<{ configInfo: AgencyConfigInfo }>()
);
export const getAgencyConfigSuccess2 = createAction(
  GET_AGENCY_CONFIG_SUCCESS2,
  props<{ pro: any }>()
);
export const getAgencyConfigFailed = createAction(
  GET_AGENCY_CONFIG_FAILED,
  props<{ error?: string }>()
);

export type AgencyConfigTypes =
  | ActionType<typeof getAgencyConfig>
  | ActionType<typeof getAgencyConfigSuccess2>
  | ActionType<typeof getAgencyConfigSuccess>
  | ActionType<typeof getAgencyConfigFailed>;
