import { environment } from '../environment';

// export const authCodeFlowConfig: Partial<any> = {
//   issuer: 'https://id-test.trueconnect.vn',
//   clientId: 'localhost_identity_short',
//   tokenEndpoint: environment.API_URL + '/connect/token',
//   userinfoEndpoint: environment.API_URL + '/connect/userinfo',
//   responseType: 'code',
//   silentRefreshRedirectUri: 'http://localhost:4200',
//   redirectUri: 'http://localhost:4200',
//   dummyClientSecret: 'no_important',
//   scope: 'offline_access roles profile openid email',
//   // customQueryParams: {
//   //   grant_type: 'refresh_token',
//   // },
//   // useSilentRefresh: true,
//   // Enables session checks
//   // showDebugInformation: true,
//   oidc: false,
//   clearHashAfterLogin: false,
//   useIdTokenHintForSilentRefresh: true,
// };

export const authCodeFlowConfig: Partial<any> = {
  issuer: environment.API_URL_LOGIN,
  tokenEndpoint: environment.API_URL_LOGIN + '/connect/token',
  userinfoEndpoint: environment.API_URL_LOGIN + '/connect/userinfo',
  clientId: 'localhost_identity_short',
  responseType: 'code',
  dummyClientSecret: 'no_important',
  scope: 'offline_access roles profile openid email',
  useSilentRefresh: true,
  oidc: false,
  clearHashAfterLogin: false,
  useIdTokenHintForSilentRefresh: true,
};
