import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, switchMap } from 'rxjs';
import * as AgencyConfigActions from '../states/agencyConfig/agencyConfig.action';

import { Store } from '@ngrx/store';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AgencyConfigService {
  _http = inject(HttpClient);
  endpoint = environment.API_URL;
  // _domainService = inject(DomainService);
  tenant = 'trueretail';
  // tenant = this._domainService.tenant();
  _store = inject(Store);
  public open: any = new BehaviorSubject(null);
  getAgencyConfig(): Observable<any> {
    return this._http
      .get<any>(this.endpoint + `/gateway/agency/detail?Agency=${this.tenant}`)
      .pipe(
        switchMap((data: any) => {
          this._store.dispatch(
            AgencyConfigActions.getAgencyConfigSuccess2({ pro: data })
          );
          return of(data);
        })
      );
  }
}
